/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { AppSettingsObject } from '../../../pages/_app';

type ContentSquareTrackingType = 'trackPageview' | 'trackPageEvent';

export type AppContextType = {
  locale: string;
  country: string;
  /**
   * lowercase language code
   *
   * @example 'nl'
   */
  language: string;
  /**
   * uppercase language code
   *
   * @example 'EN'
   */
  lang: string;
  pushToContentSquare: (trackingType: ContentSquareTrackingType, action: string) => void;
  pushToAppSettings: (appSettings: AppSettingsObject) => void;
};

const appContextDefaultValues: AppContextType = {
  locale: 'default',
  country: '',
  language: '',
  lang: '',
  pushToContentSquare: () => {},
  pushToAppSettings: () => {},
};

export const AppContext = createContext<AppContextType>(appContextDefaultValues);

export function useAppContext() {
  return useContext(AppContext);
}

type Props = {
  locale: string;
  children: ReactNode;
};

export function AppProvider({ locale, children }: Props) {
  const value = useMemo((): AppContextType => {
    const pushToContentSquare = (trackingType: ContentSquareTrackingType, payload: string) => {
      window._uxa = window._uxa || [];
      window._uxa.push([trackingType, payload]);
    };

    const pushToAppSettings = (appSettings: AppSettingsObject) => {
      window.AppSettings = window.AppSettings || [];
      window.AppSettings = { ...window.AppSettings, ...appSettings };
    };

    const LOCALE_PATTERN = /([a-zA-Z]){2}_([a-zA-Z]){2}$/;
    const [language, country] = LOCALE_PATTERN.test(locale) ? locale.split('_') : ['', ''];
    const lang = language.toUpperCase();

    return {
      locale,
      country,
      language,
      lang,
      pushToContentSquare,
      pushToAppSettings,
    };
  }, [locale]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
