import { useRouter } from 'next/router';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useDataLayerContext } from '../context/static/DataLayerContext';

export const useOuterClick = () => {
  const [outerClicked, setOuterClicked] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as HTMLDivElement)) {
      setOuterClicked(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return { ref, outerClicked, setOuterClicked };
};

export const useOuterEnterKeyPress = () => {
  const [outerEnterKeyPressed, setOuterEnterKeyPressed] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleEnterPressOutside = useCallback((e: KeyboardEvent) => {
    if (ref.current && !ref.current.contains(e.target as HTMLDivElement) && e.key === 'Enter') {
      setOuterEnterKeyPressed(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEnterPressOutside);

    return () => {
      document.removeEventListener('keydown', handleEnterPressOutside);
    };
  }, [handleEnterPressOutside]);

  return { ref, outerEnterKeyPressed, setOuterEnterKeyPressed };
};

// Workaround to set a custom html `lang` attribute different from
// the locales defined in next-i18next.config.js
// https://github.com/vercel/next.js/issues/26185#issuecomment-867004543
export const useLangObserver = (locale: string) => {
  useEffect(() => {
    const callback = (mutationsList: MutationRecord[]) => {
      mutationsList.forEach(mutation => {
        if (mutation.attributeName === 'lang' && document.documentElement.lang !== locale) {
          document.documentElement.lang = locale;
        }
      });
    };

    const observer = new MutationObserver(callback);

    observer.observe(document.documentElement, { attributes: true });

    return () => observer.disconnect();
  }, [locale]);
};

export const useHashChanged = (urlHash: string[], eventData?: object) => {
  const router = useRouter();
  const [hashMatched, setHashMatched] = useState(false);
  const { pushToDataLayer } = useDataLayerContext();

  const hashValue = useRef(typeof window !== 'undefined' ? window.location.hash : '');

  useEffect(() => {
    const onHashChanged = (e?: HashChangeEvent) => {
      const currentHash = e ? `#${e.newURL.split('#').pop() || ''}` : hashValue.current;

      if (urlHash.includes(currentHash) && router.isReady) {
        setHashMatched(true);
        window.history.replaceState(null, '', ' ');
        hashValue.current = '';

        if (eventData) {
          pushToDataLayer(eventData);
        }
      }
    };

    onHashChanged();
    window.addEventListener('hashchange', onHashChanged);

    return () => window.removeEventListener('hashchange', onHashChanged);
  }, [urlHash, router.isReady, eventData, pushToDataLayer]);

  return { hashMatched, setHashMatched };
};

export const useLazyLoading = () => {
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => entry.isIntersecting && setIsIntersecting(true));
    });

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return { ref, isIntersecting };
};

export const useEffectOnce = (callback: () => void) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);
};
