import { keyframes } from 'styled-components';
import {
  USP_ANIMATION_DURATION,
  USP_MESSAGE_DISPLAY_TIME,
} from '../../../../utilities/constants/usp';

const uspFullCycleTime = USP_ANIMATION_DURATION + USP_MESSAGE_DISPLAY_TIME + USP_ANIMATION_DURATION;
const uspAppearEndKeyframe = Math.floor((100 / uspFullCycleTime) * USP_ANIMATION_DURATION);
const uspDisappearStartKeyframe = 100 - uspAppearEndKeyframe;

export const animations = {
  pulse: keyframes`
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  `,

  hotspotPulse: keyframes`
    0% {
      opacity: 0;
      transform: scale(1);
    }
    2% {
      opacity: 0.4;
      transform: scale(1.1);
    }
    10%, 100% {
      opacity: 0;
      transform: scale(1.8);
    }
  `,

  spin: keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  `,

  headerUspsScroll: keyframes`
    0% {
      opacity: 0;
      transform: translateX(-400px);
    }

    ${`${uspAppearEndKeyframe}%`} {
      opacity: 1;
      transform: translateX(0px);
    }

    ${`${uspDisappearStartKeyframe}%`} {
      opacity: 1;
      transform: translateX(0px);
    }

    100% {
      opacity: 0;
      transform: translateX(400px);
    }
  `,
};
