// Regex
/**
 * RFC 5322 complaint email regexp
 *
 * https://emailregex.com/
 */
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * Product code should consist of 3-4 character groups:
 *
 * `(style)-(fabric)-(color)`
 *
 * Optional group at the end:
 *
 * `(style)-(fabric)-(color)-(size)`
 *
 * example of product code
 * D16411-336-2199, D16411-336-2199-S, d01896-6553-89-2330, d24884-d644-110-43-46
 *
 */

/**
 * Regex pattern to validate product codes -> PRODUCT_CODE_REGEXP, LEAN_PRODUCT_CODE_REGEXP
 *
 * Matches strings where:
 * - Each section contains a specific number of characters:
 *  - 4 to 6 characters for the first section,
 *  - 2 to 5 characters for the second section,
 *  - 2 to 4 characters for the third section.
 * after each section a hyphen has to be matched, except for the last section.
 *
 * - The last section differs between PRODUCT_CODE_REGEXP, LEAN_PRODUCT_CODE_REGEXP:
 * PRODUCT_CODE_REGEXP: can optionally contain hypen, but not end with a hyphen.
 * LEAN_PRODUCT_CODE_REGEXP:  contains a hyphen followed by 1 to 5 characters.
 */
export const PRODUCT_CODE_REGEXP =
  /^([0-9a-z]{4,6})-([0-9a-z]{2,5})-([0-9a-z]{2,4})(?:-([a-z0-9-]{0,5}[^-]))?$/i;
export const LEAN_PRODUCT_CODE_REGEXP =
  /([0-9a-z]{4,6})-([0-9a-z]{2,5})-([0-9a-z]{2,4})((?:-[a-z0-9]{1,5})*)/i;
export const REGEX_BCI = /BCI|COB/;
export const linkTargetRegex = /^[\w\d]\d{4,5}(?:-[\w\d]\d{1,3}){2}$/;
export const PDP_URL_WITH_LOCALE_REGEX =
  /[a-z]{2}_[a-z]{2}\/shop.*?\/([0-9a-z]{4,6})-([0-9a-z]{2,5})-([0-9a-z]{2,4})(-[a-z0-9]{1,5}){0,1}/i;
export const LOCALE_REGEX = /^[a-z]{2}_[a-z]{2}$/i;
export const COMBINABLE_LOCALE_REGEX = /(\/?[a-z]{2}_[a-z]{2})?/i;
export const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$/;
export const FUNCTION_BODY_REGEX = /\(\)=>\{([\s\S]*)\}/;
