/* eslint-disable react/static-property-placement */
import type { ReactNode, ErrorInfo } from 'react';
import { Component } from 'react';
import { AppContext } from '../../../utilities/context/static/AppContext';

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  static contextType = AppContext;

  componentDidMount() {
    window.onerror = (
      message: string | Event,
      scriptUrl?: string,
      lineNumber?: number,
      columnNumber?: number,
      error?: Error
    ) => {
      try {
        let errorMessage = '[FS] ';

        errorMessage += `${message} on ${scriptUrl}:${lineNumber}`;

        if (columnNumber !== undefined) {
          errorMessage += `:${columnNumber}`;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'js-error',
          events: {
            category: 'Javascript Error',
            action: errorMessage,
            label: error?.name || '',
          },
        });
      } catch (e) {
        return false;
      }

      // eslint-disable-next-line no-console
      console.log('Javascript Error: ', error);

      return true;
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'js-error',
      events: {
        category: 'Javascript Error',
        action: `[REACT FS] ${error}`,
        label: `${errorInfo?.componentStack?.split?.('in')?.[1] || errorInfo?.componentStack}`,
      },
    });

    // eslint-disable-next-line no-console
    console.log('React Error: ', { error, errorInfo });
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
