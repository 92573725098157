/* eslint-disable @typescript-eslint/no-empty-function */
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useEffect, useState, useRef } from 'react';
import type { VendorEvent } from '../../../pages/_app';

export type DataLayerContextType = {
  pushToDataLayer: (dataLayerEvent: VendorEvent, isCookieChange?: boolean) => void;
};

const appContextDefaultValues: DataLayerContextType = {
  pushToDataLayer: () => {},
};

export const DataLayerContext = createContext<DataLayerContextType>(appContextDefaultValues);

export function useDataLayerContext() {
  return useContext(DataLayerContext);
}

type Props = {
  children: ReactNode;
};

export function DataLayerProvider({ children }: Props) {
  const router = useRouter();
  const [eventsQueue, setEventsQueue] = useState<VendorEvent[]>([]);
  const isReady = useRef(false);

  useEffect(() => {
    if (router.isReady) {
      isReady.current = true;

      if (eventsQueue.length) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(...eventsQueue);
      }
    }

    return () => {
      isReady.current = false;
    };
  }, [router.isReady, eventsQueue]);

  const value = useMemo(() => {
    const pushToDataLayer = (dataLayerEvent: VendorEvent, toPush?: boolean) => {
      if (isReady.current || toPush) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataLayerEvent);
      } else {
        setEventsQueue(prev => [...prev, dataLayerEvent]);
      }
    };

    return {
      pushToDataLayer,
    };
  }, []);

  return <DataLayerContext.Provider value={value}>{children}</DataLayerContext.Provider>;
}
