import { breakpoints } from './breakpoints';
import type { Breakpoint } from './breakpoints';

function lessThan(bp: Breakpoint): string {
  return `(max-width: calc(${breakpoints[bp]} - 1px))`;
}

function greaterThan(bp: Breakpoint): string {
  return `(min-width: ${breakpoints[bp]})`;
}

function between(minBp: Breakpoint, maxBp: Breakpoint): string {
  return `${greaterThan(minBp)} and ${lessThan(maxBp)}`;
}

const canHover = '(hover: hover)';

const noHover = '(hover: none)';

const noMotionPreference = '(prefers-reduced-motion: no-preference)';

const portrait = '(orientation: portrait)';

export const media = {
  lessThan,
  greaterThan,
  between,
  canHover,
  noHover,
  noMotionPreference,
  portrait,
};
